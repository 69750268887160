import React from "react";
import Accordion from "react-bootstrap/Accordion";
import rarities from "../rarities/rarities.json";

function Rarities() {
  return (
    <div className="row justify-content-center" id="rarities">
      <div
        className="col-10 mt-5 mb-5 text-center"
        style={{ fontSize: "28px" }}
      >
        RARITIES
      </div>
      <div className="col-10 col-md-8 text-center mb-5">
        Rarities of the CRYPTO-COWS traits
      </div>
      <div className="col-10 mt-4">
        <Accordion>
          {rarities.map((rarity) => {
            if (rarity.background) {
              return (
                <Accordion.Item eventKey="0" key={"background"}>
                  <Accordion.Header>Background</Accordion.Header>
                  <Accordion.Body>
                    <div className="row" style={{color: "white"}}>
                      <div className="col-12 m-auto">
                        <div className="row">
                          <div className="col-6 text-center rarities-header padding-15px border-r-1-white border-b-1-white border-t-1-white">
                            <u>TRAIT</u>
                          </div>
                          <div className="col-6 text-center rarities-header padding-15px border-l-1-white border-b-1-white border-t-1-white">
                            <u>RARITY</u>
                          </div>
                        </div>
                      </div>
                      {rarity.background
                        .sort((a, b) =>
                          parseInt(a.occurrence.split("%")[0]) <
                          parseInt(b.occurrence.split("%")[0])
                            ? 1
                            : -1
                        )
                        .map((item) => {
                          return (
                            <div key={item.trait} className="col-12 m-auto">
                              <div className="row">
                                <div className="col-6 text-center padding-15px border-r-1-white border-b-1-white">
                                  {item.trait}
                                </div>
                                <div className="col-6 text-center padding-15px border-l-1-white border-b-1-white">
                                  {item.occurrence}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              );
            }
            if (rarity.body) {
              return (
                <Accordion.Item eventKey="1" key={"body"}>
                  <Accordion.Header>Body</Accordion.Header>
                  <Accordion.Body>
                    <div className="row" style={{color: "white"}}>
                      <div className="col-12 m-auto">
                        <div className="row">
                          <div className="col-6 text-center rarities-header padding-15px border-r-1-white border-b-1-white border-t-1-white">
                            <u>TRAIT</u>
                          </div>
                          <div className="col-6 text-center rarities-header padding-15px border-r-1-white border-b-1-white border-t-1-white">
                            <u>RARITY</u>
                          </div>
                        </div>
                      </div>
                      {rarity.body
                        .sort((a, b) =>
                          parseInt(a.occurrence.split("%")[0]) <
                          parseInt(b.occurrence.split("%")[0])
                            ? 1
                            : -1
                        )
                        .map((item) => {
                          return (
                            <div key={item.trait} className="col-12 m-auto">
                              <div className="row">
                                <div className="col-6 text-center padding-15px border-r-1-white border-b-1-white">
                                  {item.trait}
                                </div>
                                <div className="col-6 text-center padding-15px border-l-1-white border-b-1-white">
                                  {item.occurrence}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              );
            }
            if (rarity.horns) {
              return (
                <Accordion.Item eventKey="2" key={"horns"}>
                  <Accordion.Header>Horns</Accordion.Header>
                  <Accordion.Body>
                    <div className="row" style={{color: "white"}}>
                      <div className="col-12 m-auto">
                        <div className="row">
                          <div className="col-6 text-center rarities-header padding-15px border-r-1-white border-b-1-white border-t-1-white">
                            <u>TRAIT</u>
                          </div>
                          <div className="col-6 text-center rarities-header padding-15px border-r-1-white border-b-1-white border-t-1-white">
                            <u>RARITY</u>
                          </div>
                        </div>
                      </div>
                      {rarity.horns
                        .sort((a, b) =>
                          parseInt(a.occurrence.split("%")[0]) <
                          parseInt(b.occurrence.split("%")[0])
                            ? 1
                            : -1
                        )
                        .map((item) => {
                          return (
                            <div key={item.trait} className="col-12 m-auto">
                              <div className="row">
                                <div className="col-6 text-center padding-15px border-r-1-white border-b-1-white">
                                  {item.trait}
                                </div>
                                <div className="col-6 text-center padding-15px border-l-1-white border-b-1-white">
                                  {item.occurrence}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              );
            }
            if (rarity.eyes) {
              return (
                <Accordion.Item eventKey="3" key={"eyes"}>
                  <Accordion.Header>Eyes</Accordion.Header>
                  <Accordion.Body>
                    <div className="row" style={{color: "white"}}>
                      <div className="col-12 m-auto">
                        <div className="row">
                          <div className="col-6 text-center rarities-header padding-15px border-r-1-white border-b-1-white border-t-1-white">
                            <u>TRAIT</u>
                          </div>
                          <div className="col-6 text-center rarities-header padding-15px border-r-1-white border-b-1-white border-t-1-white">
                            <u>RARITY</u>
                          </div>
                        </div>
                      </div>
                      {rarity.eyes
                        .sort((a, b) =>
                          parseInt(a.occurrence.split("%")[0]) <
                          parseInt(b.occurrence.split("%")[0])
                            ? 1
                            : -1
                        )
                        .map((item) => {
                          return (
                            <div key={item.trait} className="col-12 m-auto">
                              <div className="row">
                                <div className="col-6 text-center padding-15px border-r-1-white border-b-1-white">
                                  {item.trait}
                                </div>
                                <div className="col-6 text-center padding-15px border-l-1-white border-b-1-white">
                                  {item.occurrence}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              );
            }
            if (rarity.accessories) {
              return (
                <Accordion.Item eventKey="4" key={"accessories"}>
                  <Accordion.Header>Accessories</Accordion.Header>
                  <Accordion.Body>
                    <div className="row" style={{color: "white"}}>
                      <div className="col-12 m-auto">
                        <div className="row">
                          <div className="col-6 text-center rarities-header padding-15px border-r-1-white border-b-1-white border-t-1-white">
                            <u>TRAIT</u>
                          </div>
                          <div className="col-6 text-center rarities-header padding-15px border-r-1-white border-b-1-white border-t-1-white">
                            <u>RARITY</u>
                          </div>
                        </div>
                      </div>
                      {rarity.accessories
                        .sort((a, b) =>
                          parseInt(a.occurrence.split("%")[0]) <
                          parseInt(b.occurrence.split("%")[0])
                            ? 1
                            : -1
                        )
                        .map((item) => {
                          return (
                            <div key={item.trait} className="col-12 m-auto">
                              <div className="row">
                                <div className="col-6 text-center padding-15px border-r-1-white border-b-1-white">
                                  {item.trait}
                                </div>
                                <div className="col-6 text-center padding-15px border-l-1-white border-b-1-white">
                                  {item.occurrence}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              );
            }
            return null;
          })}
        </Accordion>
      </div>
    </div>
  );
}

export default Rarities;
