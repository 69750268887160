import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { connect } from "../redux/blockchain/blockchainActions";
// import { blockchainReducer } from "../redux/blockchain/blockchainReducer";
// import detectEthereumProvider from '@metamask/detect-provider';
// import { fetchData } from "./redux/data/dataActions";
// import blockchainReducer from "../redux/blockchain/blockchainReducer";
import store from "../redux/store";
// import { setData } from "./redux/blockchain/blockchainActions";
import {
    SMART_CONTRACT,
    COST,
    openseaCollectionURL,
  } from "../config";
  import landingImg from "../assets/images/landing.gif";
  import { fetchData } from "../redux/data/dataActions";

function Mint() {

    const dispatch = useDispatch();
    // const [chain, setChain] = useState("");
    const [blockchain, setBlockchain] = useState();
    const data = useSelector((state) => state.data);
    const [feedback, setFeedback] = useState("Enter the Cowverse");
    const [claimingNft, setClaimingNft] = useState(false);

    useEffect(() => {

      const state = store.getState();

      if (!state.blockchain.account) {
  
        store.subscribe(() => {

          const bc = store.getState();
          setBlockchain(bc.blockchain);

      });
  
      } else {
        
        setBlockchain(state.blockchain);
  
      }

      }, []);

      const getData = () => {
        if (blockchain.account && blockchain.smartContract) {
          dispatch(fetchData(blockchain.account));
        }
      };

      const claimNFTs = (_amount) => {
        if (_amount <= 0) {
          return;
        }
        setFeedback("Minting your Crypto-Cow...");
        setClaimingNft(true);
        blockchain.smartContract.methods
          .mint(blockchain.account, _amount)
          .send({
            gas: "400000",
            maxPriorityFeePerGas: null,
            maxFeePerGas: null,
            to: SMART_CONTRACT,
            from: blockchain.account,
            value: blockchain.web3.utils.toWei(
              (COST * _amount).toString(),
              "ether"
            ),
          })
          .once("error", (err) => {
            // console.log(err);
            setFeedback("Sorry, something went wrong please try again later.");
            setClaimingNft(false);
          })
          .then((receipt) => {
            setFeedback(
              "WOW, you now own a Crypto-Cow. go visit Opensea.io to view it."
            );
            setClaimingNft(false);
            dispatch(fetchData(blockchain.account));
          });
        }


  return (
    <div className="row">
      <div className="col-12">
        <div className="row mb-50px">
          <div
            className="col-12 text-center mt-5 mb-4"
            style={{ fontSize: "28px" }}
          >
            MINT YOUR CRYPTO-COWS
          </div>
        </div>
        <div className="row justify-content-center mb-100px">
          <div className="col-12 col-md-5">
            <div className="row">
              <div className="col-12 mt-4 text-center">
                <img className="w-50 min-width-300px" src={landingImg} alt="Crypto-Cow NFT" />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-5">
            <div className="row">
              <div className="col-12 mt-5 mb-5">
                {!blockchain?.account ? (
                  <div className="text-center">
                    Connect your <u>METAMASK WALLET</u> to the{" "}
                    <u>POLYGON NETWORK</u> and then on the <u>WEBSITE</u> to see
                    the remaning supply and <u>MINT</u> your NFT.
                  </div>
                ) : (
                  <div
                    className="row justify-content-center text-center"
                    style={{ fontSize: "24px" }}
                  >
                    {!data.totalSupply ? (
                      <div style={{ fontSize: "12px" }}>Connecting...</div>
                    ) : data.totalSupply < 10000 ? (
                      <div className="col">
                        <div>{data.totalSupply}/10000</div>
                        {!claimingNft ? (
                          <div
                            className="col col-md-5 mt-3 text-center m-auto py-3 py-md-5 p-md-1 cursor-pointer menu-selected mb-4"
                            style={{
                              border: "1px solid white",
                              borderRadius: "10px",
                            }}
                            disabled={claimingNft ? 1 : 0}
                            onClick={(e) => {
                              e.preventDefault();
                              claimNFTs(1);
                              getData();
                            }}
                          >
                            Mint your NFT
                          </div>
                        ) : null}
                        <div className="mt-2">{feedback}</div>
                        <div className="mt-2">
                          Costs 1 MATIC excluding gas fee
                        </div>
                      </div>
                    ) : (
                      <div className="mt-5">
                        <div>
                          There are no more CRYPTO-COWS available for minting.
                        </div>
                        <div>
                          You can still find Crypto-Cows on{" "}
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={openseaCollectionURL}
                          >
                            OPENSEA
                          </a>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Mint;
