import React from "react";
import {
  openseaCollectionURL,
  twitterURL,
  githubURL,
  telegramUrl,
  discordUrl,
  instagramUrl,
} from "../config";
import moment from "moment";

function Footer() {
  return (
    <div className="row justify-content-center mb-50px mt-2">
      <hr />
      <div className="col-6 text-center mb-4">
        <div className="row">
          <div className="col-12 mb-3">CRYPTO-COWS NFT</div>
          <div className="col-6 col-sm my-3">
            <a
              className="mx-3 mx-sm-0"
              target="_blank"
              rel="noopener noreferrer"
              href={twitterURL}
            >
              TWITTER
            </a>
          </div>
          <div className="col-6 col-sm my-3">
            <a
              className="mx-3 mx-sm-0"
              target="_blank"
              rel="noopener noreferrer"
              href={discordUrl}
            >
              DISCORD
            </a>
          </div>
          <div className="col-6 col-sm my-3">
            <a
              className="mx-3 mx-sm-0"
              target="_blank"
              rel="noopener noreferrer"
              href={openseaCollectionURL}
            >
              OPENSEA
            </a>
          </div>
          <div className="col-6 col-sm my-3">
            <a
              className="mx-3 mx-sm-0"
              target="_blank"
              rel="noopener noreferrer"
              href={githubURL}
            >
              GITHUB
            </a>
          </div>
          <div className="col-6 col-sm my-3">
            <a
              className="mx-3 mx-sm-0"
              target="_blank"
              rel="noopener noreferrer"
              href={instagramUrl}
            >
              INSTAGRAM
            </a>
          </div>
          <div className="col-6 col-sm my-3">
            <a
              className="mx-3 mx-sm-0"
              target="_blank"
              rel="noopener noreferrer"
              href={telegramUrl}
            >
              TELEGRAM
            </a>
          </div>
        </div>
      </div>
      <div className="col-12 text-center mt-5" style={{fontSize: "10px"}}>
        <i>© {moment().year()} crypto-cows.com All Rights Reserved</i>
      </div>
    </div>
  );
}

export default Footer;
