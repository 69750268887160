// config
export const SMART_CONTRACT = "0xE2f767C3c1050123bfDbb0AE732E37b6d369EC4F";
export const CHAIN_ID = "137"; // POLYGON MAINNET
export const COST = 1;
export const MANTEINANCE_MODE = false;
// chain id map
export const CHAIN_LIST = [
    { id: "137", name: "polygon" },
    { id: "80001", name: "mumbai" },
    { id: "1", name: "ethereum" },
    { id: "3", name: "ropsten" },
    { id: "4", name: "rinkeby" },
    { id: "420", name: "goerli" },
    { id: "56", name: "binance smart chain" },
    { id: "97", name: "binance smart chain testnet" },
    { id: "25", name: "cronos" },
    { id: "338", name: "cronos testnet" }
];
// menu items
export const MENU_ELEMENTS = [
  {
    displayName: "Home",
    navLink: "/",
  },
  {
    displayName: "Roadmap",
    navLink: "/roadmap",
  },
  {
    displayName: "Collection",
    navLink: "/collection",
  },
  {
    displayName: "Ranch",
    navLink: "/ranch",
  },
  // {
  //   displayName: "De-fi",
  //   navLink: "/de-fi",
  // }
];
// links
export const openseaCollectionURL = 'https://opensea.io/collection/crypto-cows-nft';
export const twitterURL = 'https://twitter.com/CryptoCowsNFT';
export const githubURL = 'https://github.com/Crypto-Cows';
export const telegramUrl = 'https://t.me/cryptocowsnft';
export const discordUrl = 'https://discord.gg/su7fqSrhby';
export const instagramUrl = 'https://www.instagram.com/crypto_cows_nft';
export const whitepaperDownloadUrl = 'https://github.com/Crypto-Cows/whitepaper/raw/main/Whitepaper.pdf';
export const whitepaperRepositoryUrl = 'https://github.com/Crypto-Cows/whitepaper';
// api
export const SERVER_API_URL = "https://www.crypto-cows.com";
// local
// export const SERVER_API_URL = "http://localhost:5000";