import React from "react";
import {
  openseaCollectionURL,
  twitterURL,
  githubURL,
  telegramUrl,
  discordUrl,
  instagramUrl,
} from "../config";
// import nftCalendarLogo from "../assets/images/nft-calendar-logo.png";
import cc4 from "../assets/images/CC4.png";
import cc5 from "../assets/images/CC5.png";
import cc6 from "../assets/images/CC6.png";
import logoPixtalgia from "../assets/images/Logo-Pix.png";

function Partners() {
  return (
    <div className="row justify-content-center" id="about">
      <div
        className="col-10 col-md-12 text-center mt-5"
        style={{ fontSize: "28px" }}
      >
        PARTNERS
      </div>
      <div className="col-10 col-md-8 mt-3 mb-5">
        <div className="row my-50px">
          <div className="col-12 col-md-4 text-center">
            <img
              className="w-100 max-width-200px"
              src={logoPixtalgia}
              alt="About CRYPTO-COW"
            />
          </div>
          <div className="col-12 col-md-8 text-center text-md-start mt-5 mt-md-0">
            <div>
              Pixtalgia is a retro style nft game on Wax Blockchain, we are
              working with Pixtalgia supporting them on the Blockchain
              development side, we are helping them to land on multiple
              blockchains by developing for them a multi-chain authentication
              module. to thank us for our help, the guys at Pixtalgia have
              added, only for Crypto-Cows holders, the possibility of using a
              Crypto-Cow as a playable character in their game.
              <br />
              <br />
              Website:{" "}
              <a href="https://www.pixtalgia.com/" target="_blank">
                www.pixtalgia.com
              </a>
            </div>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-12 text-center mt-5 mb-3">
            Do you want to build a partnership with us?<br /> Email <u>partnerships@crypto-cows.com</u>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Partners;
