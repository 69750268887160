import React from "react";
import {
  openseaCollectionURL,
  twitterURL,
  githubURL,
  telegramUrl,
  discordUrl,
  instagramUrl,
} from "../config";

function DeFi() {
  return (
    <div className="row justify-content-center my-100px h-60vh">
      <div className="col-12">
        <div className="row">
          <h2 className="text-center" style={{fontSize: "20px"}}>
            Here is where you will find our DE-FI platform. <br />
            <br />
            We are working on it right now.
            <br />
            Follow our channels to stay updated.
          </h2>
        </div>
        <div className="row justify-content-center">

        <div className="col-6 text-center" style={{marginTop: "40px"}}>
        <div className="row">
          <div className="col-6 col-md my-3">
            <a
              className="mx-3 mx-md-0"
              target="_blank"
              rel="noopener noreferrer"
              href={twitterURL}
            >
              TWITTER
            </a>
          </div>
          <div className="col-6 col-md my-3">
            <a
              className="mx-3 mx-md-0"
              target="_blank"
              rel="noopener noreferrer"
              href={discordUrl}
            >
              DISCORD
            </a>
          </div>
          <div className="col-6 col-md my-3">
            <a
              className="mx-3 mx-md-0"
              target="_blank"
              rel="noopener noreferrer"
              href={openseaCollectionURL}
            >
              OPENSEA
            </a>
          </div>
          <div className="col-6 col-md my-3">
            <a
              className="mx-3 mx-md-0"
              target="_blank"
              rel="noopener noreferrer"
              href={githubURL}
            >
              GITHUB
            </a>
          </div>
          <div className="col-6 col-md my-3">
            <a
              className="mx-3 mx-md-0"
              target="_blank"
              rel="noopener noreferrer"
              href={instagramUrl}
            >
              INSTAGRAM
            </a>
          </div>
          <div className="col-6 col-md my-3">
            <a
              className="mx-3 mx-md-0"
              target="_blank"
              rel="noopener noreferrer"
              href={telegramUrl}
            >
              TELEGRAM
            </a>
          </div>
        </div>
      </div>
        </div>
      </div>
    </div>
  );
}

export default DeFi;
