import React from "react";
import HowItWorks from "./HowItWorks";
import Rarities from "./Rarities";
import cc8 from "../assets/images/CC8.png";
import cc9 from "../assets/images/CC9.png";
import cc10 from "../assets/images/CC10.png";
import cc11 from "../assets/images/CC11.png";
import cc12 from "../assets/images/CC12.png";
import cc13 from "../assets/images/CC13.png";
import ranchLogo from "../assets/images/ranch-logo.png";
import breedingImg from "../assets/images/breeding.png";
import milkingImg from "../assets/images/milking.png";
import launchpadImg from "../assets/images/launchpad.png";

function Collection() {
  return (
    <div className="row justify-content-center mb-100px">
      <div
        className="col-10 col-md-12 text-center mt-3 mb-2"
        style={{ fontSize: "28px" }}
      >
        ABOUT THE COLLECTION
      </div>
      <div className="col-12">
        <div className="row px-5">
          <div className="col-12 col-md">
            <img className="w-100" src={cc8} alt="Crypto-Cow" />
          </div>
          <div className="col-12 col-md d-none d-md-block">
            <img className="w-100" src={cc9} alt="Crypto-Cow" />
          </div>
          <div className="col-12 col-md d-none d-md-block">
            <img className="w-100" src={cc10} alt="Crypto-Cow" />
          </div>
          <div className="col-12 col-md d-none d-md-block">
            <img className="w-100" src={cc11} alt="Crypto-Cow" />
          </div>
          <div className="col-12 col-md d-none d-md-block">
            <img className="w-100" src={cc12} alt="Crypto-Cow" />
          </div>
          <div className="col-12 col-md d-none d-md-block">
            <img className="w-100" src={cc13} alt="Crypto-Cow" />
          </div>

        </div>
      </div>
      <HowItWorks />
      <hr />
  





      <div className="row justify-content-center">
        <div
          className="col-10 col-md-8 text-center mt-3"
          style={{ fontSize: "28px" }}
        >
          Plans for near future
        </div>
        <div
          className="col-10 col-md-8 text-center mb-5"
          style={{ fontSize: "14px" }}
        >
          About expanding the ecosystem
        </div>
        <div className="col-10 col-md-8 text-justify mb-5">
          As soon as our collection runs out, these are the features we plan to add. <br />
          <br />
          We like to call it "expansions" because every application will provide
          additional services for our holders and literally expand the cowverse.
        </div>
        <div
          className="col-10 col-md-8 text-center text-md-start mt-5 mb-5"
          style={{ fontSize: "24px" }}
        >
          <s>expansion 1: RANCH</s><br />
          <span style={{fontSize: "12px"}}>released</span>
        </div>
        <div className="col-10 col-md-8">
          <div className="row">
            <div className="col-12 col-md-8 text-center text-md-start">
              <div>
                the place in the cowverse where crypto-cows live, the place
                where crypto-cows are happy, the place where you can interact
                with them! Ranch will be also available for mobile.
              </div>
            </div>
            <div className="col-12 col-md-4 text-center mt-5 mt-md-0">
              <img
                className="w-100 max-width-300px"
                src={ranchLogo}
                alt="Crypto-Cows NFT Ranch Logo"
              />
            </div>
          </div>
          <hr />
        </div>
        <div
          className="col-10 col-md-8 text-center text-md-start mt-5 mb-5"
          style={{ fontSize: "24px" }}
        >
          expansion 2: MILKING
        </div>
        <div className="col-10 col-md-8 mb-5">
          <div className="row">
            <div className="col-12 col-md-8 text-center text-md-start">
              <div>
                That's the real utility of Crpyto-Cows. lock on staking your
                crypto-cows for a certain period of time for milking, they will
                produce crypto-cows-milk tokens for you and this will create a
                scarsity of assets.
              </div>
            </div>
            <div className="col-12 col-md-4 text-center mt-5 mt-md-0">
              <img
                className="w-100 max-width-300px"
                src={milkingImg}
                alt="Crypto-Cows NFT Milking Logo"
              />
            </div>
          </div>
          <hr />
        </div>
        <div
          className="col-10 col-md-8 text-center text-md-start mt-5 mb-5"
          style={{ fontSize: "24px" }}
        >
          expansion 3: Breeding
        </div>
        <div className="col-10 col-md-8 mb-5">
          <div className="row">
            <div className="col-12 col-md-8 text-center text-md-start">
              <div>
                become a breeder in the cowverse! stake 2 crypto-cows for a
                certain period of time and get nft rewards, randomness and rare
                traits will give you more valuable rewards! We will launch
                breending at collection sold out.
              </div>
            </div>
            <div className="col-12 col-md-4 text-center mt-5 mt-md-0">
              <img
                className="w-100 max-width-300px"
                src={breedingImg}
                alt="Crypto-Cows NFT Launchpad Logo"
              />
            </div>
          </div>
        </div>
      </div>













      <hr />
      <Rarities />
    </div>
  );
}

export default Collection;
