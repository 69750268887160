import React from "react";
import cc1 from "../assets/images/CC1.png";
import cc2 from "../assets/images/CC2.png";
import cc3 from "../assets/images/CC3.png";

function Team() {
  return (
    <div className="row justify-content-center">
      <div
        className="col-10 col-md-12 text-center mt-5 mb-4"
        style={{ fontSize: "28px" }}
      >
        TEAM
      </div>
      <div className="col-8 mb-2 text-center">
        Transparency is at the foundalmentals of the BLOCKCHAIN so we cannot
        don't show you our fully doxxed team! <br />
        <br />
        You can find both personals and professionals profiles of the team that
        work hard on the CRYPTO-COWS ecosystem.
      </div>
      <div className="col-10 col-md-7 mt-5 mb-5 text-center">
        <div className="row">
          <div className="col-12 col-md my-3 my-md-0">
            <img
              className="w-100 border-1-white"
              src={cc1}
              alt="Simoblaster's CRYPTO-COW"
            />
            <div className="row">
              <div className="col-12 text-center mt-3">SIMONE SATURNO</div>
              <div className="col-12 text-center mb-4">DEVELOPER</div>
              <div className="col-12 text-center mb-2">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.linkedin.com/in/simone-saturno-5449b098/"
                >
                  LINKEDIN
                </a>
              </div>
              <div className="col-12 text-center mb-2">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://twitter.com/simoblaster"
                >
                  TWITTER
                </a>
              </div>
              <div className="col-12 text-center">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://twitter.com/simoblaster"
                >
                  INSTAGRAM
                </a>
              </div>
            </div>
          </div>
          <hr className="d-block d-md-none" />
          <div className="col-12 col-md my-3 my-md-0">
            <img
              className="w-100 border-1-white"
              src={cc3}
              alt="Nicolò's CRYPTO-COW"
            />
            <div className="row">
              <div className="col-12 text-center mt-3">NICOLO' SATURNO</div>
              <div className="col-12 text-center mb-4">GRAPHIC DESIGNER</div>
              <div className="col-12 text-center mb-2">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.instagram.com/s4turnone/?hl=it"
                >
                  PORTFOLIO
                </a>
              </div>
              <div className="col-12 text-center">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.instagram.com/nayk1612/?hl=it"
                >
                  INSTAGRAM
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Team;
