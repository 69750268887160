import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import cc7 from "../assets/images/CC7.png";
import { whitepaperRepositoryUrl } from '../config';

function Roadmap() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div>
      <div className="row justify-content-center" id="roadmap">
        <div
          className="col-10 text-center mt-5 mb-2"
          style={{ fontSize: "28px" }}
        >
          Roadmap
        </div>
        <div
          className="col-10 text-center mb-5"
          style={{ fontSize: "14px" }}
        >
          Main goals:
        </div>
        <div className="col-8 mb-5">
          <div className="row">
            <div className="col-12 col-md-6">
              <ul>
                <li>Improve our presence as NFT Brand.</li>
                <li className="my-100px">Officially found the Company.</li>
                <li>Expand the ecosystem.</li>
              </ul>
            </div>
            <div className="col-12 col-md-6 mt-5 mt-md-0 text-center">
              <img
                src={cc7}
                className="w-50 min-width-200px max-width-300px"
                alt="Roadmap CRYPTO-COW"
              />
            </div>
            <hr className="mt-5" />
          </div>
        </div>
        <div className="col-10 col-md-8 mt-5" style={{marginBottom: "120px"}}>
          For a better understanding about what we are going to build more in details
          we highly recommend you to read our <a href={whitepaperRepositoryUrl} target="_blank">WHITEPAPER</a>.
        </div>
      </div>
    </div>
  );
}

export default Roadmap;
