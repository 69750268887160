import React from "react";
import img from "../assets/images/test/6.png";
import About from "./About";
// import Roadmap from './Roadmap';
import Team from "./Team";
import { Link } from "react-router-dom";
import Partners from "./Partners";

function Home() {

  return (
    <div className="row">
      <div className="col-12 p-0 d-none d-md-block">
        <img className="w-100 home-wallpaper" src={img} alt="Enter the Cowverse" />
        <div className="row justify-content-center home-wallpaper-text font-size-60px px-4">
          <div className="col-6 text-center border-1-white border-radius-10 py-5 mint-home-bg">
            <div className="row">
              <div className="col-12">ENTER THE COWVERSE</div>
              <div className="col-12 mt-5">
                <Link to="/mint">
                  <button className="border-radius-10 py-2 px-4 home-mint-btn">
                    MINT
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 p-0 d-block d-md-none text-center mt-5 mb-5">
        <Link to="/mint">
          <button className="border-radius-10 py-2 px-4 home-mint-btn" style={{fontSize: "50px"}}>
            MINT
          </button>
        </Link>
      </div>
      <About />
      <hr />
      <Partners />
    </div>
  );
}

export default Home;
