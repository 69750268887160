import React from "react";

function HowItWorks() {
  return (
    <div className="row justify-content-center" id="how-it-works">
      <div className="col-10 col-md-8 text-justify mt-5 mb-5 border-x-2">
        <u>CRYPTO-COWS</u> is a collection consisting of 10.000 pieces
        that are ready to be minted on the Polygon Network at the cost of <u>1 MATIC</u> (excluding a
        little gas fee) per single NFT. <br />
        <br />
        All NFTs are generated by an algorithm that guarantees their uniqueness
        on their <u>TRAITS</u> and depending on your luck you may be rewarded
        with a <u>RARE</u> traits NFT rather than a <u>COMMON</u> traits NFT{" "}
        <br />
        <br />
        For mint your own NFT you have to select <u>POLYGON NETWORK</u> on your{" "}
        <u>METAMASK WALLET</u>, press the <u>MINT</u> button and approve the
        transaction.
        <br />
        You will recieve your NFT in your wallet after a few seconds.
        <br />
        Good luck minting of your NFTs! :D
      </div>
    </div>
  );
}

export default HowItWorks;
