import React, { useEffect, useState } from "react";
import axios from "axios";
import heartIcon from "../assets/images/heart-icon.png";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import audio1 from "../assets/sounds/1.wav";
import audio2 from "../assets/sounds/2.wav";
import audio3 from "../assets/sounds/3.wav";
import audio4 from "../assets/sounds/4.wav";
import audio5 from "../assets/sounds/5.wav";
import audio6 from "../assets/sounds/6.wav";
import audio7 from "../assets/sounds/7.wav";
import audio8 from "../assets/sounds/8.wav";
import audio9 from "../assets/sounds/9.wav";
import audio10 from "../assets/sounds/10.wav";
import audio11 from "../assets/sounds/11.wav";
import audio12 from "../assets/sounds/12.wav";
import audio13 from "../assets/sounds/13.wav";
import audio14 from "../assets/sounds/14.wav";
import audio15 from "../assets/sounds/15.wav";
import { SERVER_API_URL } from "../config";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVolumeHigh } from '@fortawesome/free-solid-svg-icons'
import { faQrcode } from '@fortawesome/free-solid-svg-icons'
import store from "../redux/store";

function Ranch() {
  // const [tokens, setTokens] = useState([]);
  const [blockchain, setBlockchain] = useState();
  const [res, setRes] = useState(null);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [cow, setCow] = useState(null);
  const handleClose = () => setShow(false);
  const handleShow = (selected) => {
    setShow(true);
    setCow(selected);
  };

  const audioFiles = [
    audio1,
    audio2,
    audio3,
    audio4,
    audio5,
    audio6,
    audio7,
    audio8,
    audio9,
    audio10,
    audio11,
    audio12,
    audio13,
    audio14,
    audio15,
  ];

  const start = () => {
    const randomElement =
      audioFiles[Math.floor(Math.random() * audioFiles.length)];
    const audio = new Audio(randomElement);

    audio.play();
  };

  function formatNumber(num) {
    let result;

    if (num.toString().length === 1) {
      result = "000" + num.toString();
    } else if (num.toString().length === 2) {
      result = "00" + num.toString();
    } else if (num.toString().length === 3) {
      result = "0" + num.toString();
    } else {
      result = num.toString();
    }

    return result;
  }



  useEffect(() => {

    const fetchData = async (params) => {
      await axios(SERVER_API_URL + "/api/getMetadata/", { params })
        .then((response) => {
          setRes(response.data);
          setLoading(false);
        })
        .catch(() => {
          // console.log('error', error);
          setLoading(false);
        });
    };

    let doRequest = false;

    const state = store.getState();

    if (!state.blockchain.account) {

      store.subscribe(() => {
      
        const bc = store.getState();
        const account = bc.blockchain.account;
  
        if (!doRequest && account) {

          doRequest = true;
          setBlockchain(true);
          setLoading(true);
          fetchData(account);
          
        }
  
      });

    } else {
      
      doRequest = true;
      setBlockchain(true);
      setLoading(true);
      fetchData(state.blockchain.account);

    }

    // eslint-disable-next-line
  }, []);

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Pet your Cow
    </Tooltip>
  );

  return (
    <div className="row">
      {blockchain ? (
        <div className="col-12">
          <div className="row justify-content-center" id="ranch">
            <div
              className="col-10 mt-5  text-center"
              style={{ fontSize: "28px" }}
            >
              RANCH
            </div>
            <div
              className="col-10 mt-5 text-start"
              style={{ fontSize: "12px" }}
            >
              turn on sound for the full ranch experience!{" "}
              <FontAwesomeIcon icon={faVolumeHigh} />
            </div>
            <div
              className="col-10 mb-5 mt-3 text-start"
              style={{ fontSize: "12px" }}
            >
              <button className="btn btn-secondary" disabled>
                Generate QR code <FontAwesomeIcon icon={faQrcode} />
              </button>
            </div>
            {loading ? (
              <div className="row mb-5 h-60vh">
                <div className="col text-center">Loading Ranch...</div>
              </div>
            ) : res !== null && res.length > 0 ? (
              <div className="row">
                <div className="col-12 mb-5 ranch-bg p-4">&nbsp;</div>
                <div className="col-12">
                  <div className="row">
                    {res.map((item, index) => (
                      <div
                        key={"asset" + index}
                        className="col-12 col-sm-4 col-md-3 col-lg-2 mb-5 cow-card p-2"
                      >
                        <img
                          className="w-100 cursor-pointer"
                          key={"imgToken" + index}
                          src={"data:image/png;base64," + item.image}
                          onClick={() => {
                            handleShow(item);
                          }}
                          alt="CRYPTO-COW"
                        />
                        <div className="text-center">
                          CRYPTO-COW #{formatNumber(item.edition)}
                        </div>
                        <div className="text-center mt-2">
                          <OverlayTrigger
                            placement="bottom"
                            overlay={renderTooltip}
                          >
                            <img
                              onClick={start}
                              className="w-10 cursor-pointer"
                              src={heartIcon}
                              alt="INTERACT WITH CRYPTO-COW"
                            />
                          </OverlayTrigger>
                        </div>
                        <div
                          className="text-center cursor-pointer mt-2"
                          style={{
                            border: "1px solid white",
                            borderRadius: "10px",
                            padding: "5px",
                          }}
                          onClick={() => {
                            handleShow(item);
                          }}
                        >
                          details
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="col-12 mb-5 ranch-bg p-4">&nbsp;</div>
                {cow ? (
                  <Modal
                    show={show}
                    onHide={handleClose}
                    size="md"
                    className="ranch-modal"
                  >
                    <Modal.Header>
                      <Modal.Title>CRYPTO-COW #{cow.edition}</Modal.Title>
                      <span className="cursor-pointer" onClick={handleClose}>
                        X
                      </span>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="row">
                        <div className="col">
                          <img
                            className="w-100"
                            src={"data:image/png;base64," + cow.image}
                            alt="CRYPTO-COW"
                          />
                        </div>
                      </div>
                      <div className="row mt-3">
                        {cow.attributes.map((item, index) => (
                          <div
                            className="col-12 p-3"
                            key={"attr" + index}
                            style={
                              index === 0
                                ? {
                                    borderTop: "1px solid white",
                                    borderBottom: "1px solid white",
                                  }
                                : { borderBottom: "1px solid white" }
                            }
                          >
                            <div className="row">
                              <div className="col-6 my-auto">
                                {item.trait_type}:
                              </div>
                              <div className="col-6 my-auto">{item.value}</div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </Modal.Body>
                  </Modal>
                ) : null}
              </div>
            ) : res !== null && res.length === 0 ? (
              <div className="row mb-5 h-60vh">
                <div className="col text-center">
                  You're not a CRYPTO-COWS holder, nothing to display for you!
                  :) <br />
                  If you just purchased your CRYPTO-COW just wait for data to
                  indexing, come back later!
                </div>
              </div>
            ) : !res ? (
              <div className="row mb-5 h-60vh">
                <div className="col text-center">
                  An error occurred, please try again later :(
                </div>
              </div>
            ) : null}
          </div>
        </div>
      ) : (
        <div className="row justify-content-center my-100px h-60vh">
          <div className="row">
            <h2 className="text-center" style={{ fontSize: "20px" }}>
              Connect your wallet to the platfrom for enter the Ranch
            </h2>
          </div>
        </div>
      )}
    </div>
  );
}

export default Ranch;
