import React from "react";
import {
  openseaCollectionURL,
  twitterURL,
  githubURL,
  telegramUrl,
  discordUrl,
  instagramUrl,
} from "../config";
// import nftCalendarLogo from "../assets/images/nft-calendar-logo.png";
import cc4 from "../assets/images/CC4.png";
import cc5 from "../assets/images/CC5.png";
import cc6 from "../assets/images/CC6.png";

function About() {
  return (
    <div className="row justify-content-center" id="about">
      <div
        className="col-10 col-md-12 text-center mt-5 mb-4"
        style={{ fontSize: "28px" }}
      >
        ABOUT CRYPTO-COWS
      </div>
      <div className="col-12 col-md-6 text-center mb-3">
        <div className="row">
          <div className="col-12 mb-2">CRYPTO-COWS NFT ON</div>
          <div className="col-6 col-md my-3">
            <a
              className="mx-3 mx-md-0"
              target="_blank"
              rel="noopener noreferrer"
              href={twitterURL}
            >
              TWITTER
            </a>
          </div>
          <div className="col-6 col-md my-3">
            <a
              className="mx-3 mx-md-0"
              target="_blank"
              rel="noopener noreferrer"
              href={discordUrl}
            >
              DISCORD
            </a>
          </div>
          <div className="col-6 col-md my-3">
            <a
              className="mx-3 mx-md-0"
              target="_blank"
              rel="noopener noreferrer"
              href={openseaCollectionURL}
            >
              OPENSEA
            </a>
          </div>
          <div className="col-6 col-md my-3">
            <a
              className="mx-3 mx-md-0"
              target="_blank"
              rel="noopener noreferrer"
              href={githubURL}
            >
              GITHUB
            </a>
          </div>
          <div className="col-6 col-md my-3">
            <a
              className="mx-3 mx-md-0"
              target="_blank"
              rel="noopener noreferrer"
              href={instagramUrl}
            >
              INSTAGRAM
            </a>
          </div>
          <div className="col-6 col-md my-3">
            <a
              className="mx-3 mx-md-0"
              target="_blank"
              rel="noopener noreferrer"
              href={telegramUrl}
            >
              TELEGRAM
            </a>
          </div>
        </div>
        {/* <div className="row mt-5">
          <div className="col-12">Partners</div>
          <div className="col-12">
            <a
              href="https://nftcalendar.io/event/crypto-cows-nft/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={nftCalendarLogo}
                alt="NFT Calendar Logo"
                style={{ width: "100px" }}
              />
            </a>
          </div>
        </div> */}
      </div>
      <div className="col-10 col-md-8 mt-5 mb-5">
        <div className="row my-50px mt-5">
          <div className="col-12 col-md-8 text-center text-md-start">
            <div>
              <u>CRYPTO-COWS</u> is an NFT project, created by a Blockchain
              Startup on the way to be founded, which aims to enter the world of
              Blockchain at 360°. We want to cover all the aspects of the
              Blockchain environment joining de-fi, nft and game-fi sectors. As
              startup we also want to provide consulting services and Blockchain
              solutions for people and companies that wants to adopt Blockchain
              based systems or applications.
            </div>
          </div>
          <div className="col-12 col-md-4 text-center mt-5 mt-md-0">
            <img
              className="w-100 max-width-200px"
              src={cc4}
              alt="About CRYPTO-COW"
            />
          </div>
        </div>
        <hr />
        <div className="row my-50px">
          <div className="col-12 col-md-4 text-center">
            <img
              className="w-100 max-width-200px"
              src={cc5}
              alt="About CRYPTO-COW"
            />
          </div>
          <div className="col-12 col-md-8 text-center text-md-end mt-5 mt-md-0">
            {/* <div>
                Our group is composed of proven professionals in the IT sector who
                strongly believe in the use of Blockchain in the world as a{" "}
                <u>DECENTRALIZED</u>, <u>UNSTOPPABLE</u> and <u>INCENSURABLE</u>{" "}
                system.
              </div> */}
            <div>
              Our goal is to find projects that needs a boost on the
              development, we actively search for innovative projects for build
              a partership and work with them. We want to help projects to reach
              their goals and give them visibility through our brand.
            </div>
          </div>
        </div>
        <hr />
        <div className="row my-50px">
          <div className="col-12 col-md-8 text-center text-md-start">
            <div>
              We also want to build a <u>launchpad</u> where our partners will give
              away a part of their nft supply or their tokens to our users that
              will partecipating in launch pools by locking on staking nfts or tokens.
            </div>
          </div>
          <div className="col-12 col-md-4 text-center mt-5 mt-md-0">
            <img
              className="w-100 max-width-200px"
              src={cc6}
              alt="About CRYPTO-COW"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
